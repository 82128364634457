import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/services/services-details1.jpg'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Social balance and Monitoring and Evaluation" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="Social balance and Monitoring and Evaluation" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Social balance and Monitoring and Evaluation</span>
                            <h3>SB & MEAL</h3>
                            <p>Social reporting is made easy, with innovative tools and methodologies.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Monitoring and evaluation</li>
                                            <li>Standard indicators measurement</li>
                                            <li>Social balance</li>
                                            <li>Theory Based methods</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p> With the reform of the law on the third sector, it has become fundamental for every organization to be able to report to its stakeholders on the achievement of its strategic objectives. Gnucoop can support you in the creation of a monitoring plan tailored to your needs, providing ad-hoc tools and solutions that greatly facilitate the collection and organization of data for the measurement of indicators. Whether it's a single project or you want to measure the impact of your entire organization, our solutions allow you to focus on content, reducing work overload and making data collection part of your normal workflow.
                                </p>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Java</span></li>
                                <li><span>C/CPP</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Ruby</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
             <ul className="services-list">
                <li>
                    <Link to="/app-development" >
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation" className="active">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/case-management">
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/soleterre">
                                    Soleterre 
                                </Link>
                            </h3>
                            <p>Creation of an internal monitoring and evaluation system to measure organisation strategies</p>

                            <Link to="/project/soleterre" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/csvref">
                                    CSVRef 
                                </Link>
                            </h3>
                            <p>Implementation of the impact evaluation activity within the initiative of widespread reception in the national territory for the benefit of people from Ukraine</p>

                            <Link to="/project/csvref" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                        </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/cc">
                                    Contaminiamoci di cultura 
                                </Link>
                            </h3>
                            <p>Platform to monitor and evaluate activities of the project</p>

                            <Link to="/project/cc" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/kalms">
                                    KALMS
                                </Link>
                            </h3>
                            <p>Theory of Change for Consorzio Comunità Brianza</p>

                            <Link to="/project/kalms" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/quelimane-agricola">
                                    Quelimane Agricola
                                </Link>
                            </h3>
                            <p>Monitoring of Quelimane Agricola project with Manitese in Mozambique</p>
                            
                            <Link to="/project/quelimane-agricola" className="read-more-btn">
                            Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/crise-oubliee-tchad">
                                Acra CIAD

                                </Link>
                            </h3>
                            <p>Administrative and narrative monitoring of CSOs and subgranting in Chad. </p>
                            
                            <Link to="/project/crise-oubliee-tchad" className="read-more-btn">
                            Read More <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/test-and-treat">
                                    Test and Treat
                                </Link>
                            </h3>
                            <p>Health project monitoring in Tanzania with CUAMM</p>
                            
                            <Link to="/project/test-and-treat" className="read-more-btn">
                            Read More  <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/development-data-platform">
                                    DDP Platform
                                </Link>
                            </h3>
                            <p>Analysis and mapping of international cooperation projects of Legacoop Cooperatives</p>
                            
                            <Link to="/project/development-data-platform" className="read-more-btn">
                            Read More  <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/mapping-response-covid">
                                    OCDC Platform
                                </Link>
                            </h3>
                            <p>Cooperative asset mapping as a response to the Covid-19 outbreak</p>
                            
                            <Link to="/project/mapping-response-covid" className="read-more-btn">
                            Read More  <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/foreseeing-inclusion">
                                    Foreseeing inclusion
                                </Link>
                            </h3>
                            <p>Improvement of eye care services in some districts of Northern Uganda</p>
                            
                            <Link to="/project/foreseeing-inclusion" className="read-more-btn">
                            Read More  <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                </div>
            </div>
        </section>
    )
}

export default Details
